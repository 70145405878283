import React from "react";

// reactstrap components
// import { Container, Row, Col } from "reactstrap";

// core components

function Images() {
  return (
    <>
      <div className="mt-10">
        {/* <p>
        An information system called Digital Field Data Monitoring System (DFDMS)- is developed in order to monitor the farmers and producer groups in the field. This application will be used from whole Bangladesh (up to Union level).
        </p> */}
        {/* <Container>
          <Row>
            <Col md="5">
              <div className="sw_home_img">
                <img
                  alt="..."
                  src={require("assets/img/dispensing_vector_small.png")}
                ></img>
              </div>
            </Col>
            <Col md="7">
              <div className="sw_home_content">
                <p>
                 This is DFDMS home page. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

                <p>
                 This is DFDMS home page. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </div>
            </Col>
          </Row>
        </Container> */}
      </div>
    </>
  );
}

export default Images;
